import throttle from 'lodash/throttle';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { TestimonialSlideMetadata } from '~/modules/cosmic/modules/Testimonial';
import { SLIDE_TRANSITION_DESKTOP_MS } from '~/modules/cosmic/modules/Testimonial/Testimonial.constants';
import { getSlidePosition } from '~/modules/cosmic/modules/Testimonial/Testimonial.utils';

import TestimonialSliderDesktopNextButton from './TestimonialSliderDesktopNextButton';
import TestimonialSliderDesktopSlide from './TestimonialSliderDesktopSlide';

type Props = {
  slides: TestimonialSlideMetadata[];
};

type SliderState = {
  currentIndex: number;
  isAnimated: boolean;
};

const TestimonialSliderDesktop: React.FC<Props> = ({ slides }) => {
  const displayedSlides = useMemo(
    () => [...slides, ...slides.slice(0, 2)],
    [slides]
  );

  const [sliderState, setSliderState] = useState<SliderState>({
    currentIndex: 0,
    isAnimated: true,
  });

  const debouncedSetSliderState = useMemo(
    () =>
      throttle(setSliderState, SLIDE_TRANSITION_DESKTOP_MS, {
        leading: true,
        trailing: false,
      }),
    []
  );

  const handleNextClick = useCallback(() => {
    debouncedSetSliderState((prevSliderState) => ({
      currentIndex: prevSliderState.currentIndex + 1,
      isAnimated: true,
    }));
  }, [debouncedSetSliderState]);

  useEffect(() => {
    if (sliderState.currentIndex === slides.length) {
      window.setTimeout(() => {
        setSliderState({ currentIndex: 0, isAnimated: false });
      }, SLIDE_TRANSITION_DESKTOP_MS + 16);
    }
  }, [sliderState, slides.length]);

  const renderSlide = useCallback(
    (slide: TestimonialSlideMetadata, index: number) => {
      const key = `${slide.name}${index >= slides.length ? '-repeated' : ''}`;
      const isRepeated = index >= slides.length;
      const isHidden = Math.abs(sliderState.currentIndex - index) > 1;
      const position = getSlidePosition(sliderState.currentIndex, index);

      return (
        <TestimonialSliderDesktopSlide
          key={key}
          slide={slide}
          position={position}
          isAnimated={sliderState.isAnimated}
          isHidden={isHidden}
          isRepeated={isRepeated}
        />
      );
    },
    [sliderState, slides.length]
  );

  return (
    <div className="xs:mr-[72px] relative h-[420px] bg-black sm:mr-14 md:h-[420px]">
      {displayedSlides.map(renderSlide)}
      <div className="z-2 absolute -right-10 bottom-0 top-0 m-auto size-20 sm:-right-[104px]">
        <TestimonialSliderDesktopNextButton onClick={handleNextClick} />
      </div>
    </div>
  );
};

export default React.memo(TestimonialSliderDesktop);
