import { Button } from '@finn/design-system/atoms/button';
import { VolumeNo } from '@finn/design-system/icons/volume-no';
import { VolumeYes } from '@finn/design-system/icons/volume-yes';
import { Image } from '@finn/ui-components';
import { cn, getCloudinaryImgUrl } from '@finn/ui-utils';
import React, { useCallback, useContext, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import {
  SLIDE_CONTENT_HEIGHT,
  SLIDE_CONTENT_WIDTH,
} from './Testimonial.constants';
import { TestimonialSlideMetadata } from './Testimonial.types';
import { TestimonialPlaybackContext } from './TestimonialPlaybackContext';

const VIDEO_FADE_IN_MS = 300;

type Props = {
  slide: TestimonialSlideMetadata;
  isActive: boolean;
};

const TestimonialVideoPreview: React.FC<Props> = ({ slide, isActive }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { isInViewport, isMuted, mute, unmute } = useContext(
    TestimonialPlaybackContext
  );

  const toggleMute = useCallback(() => {
    if (!isActive) {
      return;
    }

    if (videoRef.current && isMuted) {
      videoRef.current.currentTime = 0;
    }

    if (isMuted) {
      unmute();
    } else {
      mute();
    }
  }, [isActive, isMuted, mute, unmute]);

  return (
    <Button
      type="button"
      className={cn(
        'relative block cursor-pointer overflow-hidden border-none bg-transparent p-0 outline-none',
        'h-full w-full hover:bg-transparent active:bg-transparent'
      )}
      style={{ width: SLIDE_CONTENT_WIDTH, height: SLIDE_CONTENT_HEIGHT }}
      onClick={toggleMute}
    >
      <svg viewBox={`0 0 ${SLIDE_CONTENT_WIDTH} ${SLIDE_CONTENT_HEIGHT}`} />
      <Image
        variant="lazy-loading"
        alt={slide.name}
        src={getCloudinaryImgUrl(slide.preview.url, {
          dpr: 2,
          w: 240,
        })}
        className="absolute inset-0 block h-full w-full object-contain"
        width={SLIDE_CONTENT_WIDTH}
        height={SLIDE_CONTENT_HEIGHT}
      />
      {slide?.video?.url && (
        <>
          <CSSTransition
            mountOnEnter
            unmountOnExit
            in={isActive && isInViewport}
            timeout={VIDEO_FADE_IN_MS}
            classNames={{
              enter: 'opacity-0',
              enterActive: 'opacity-1 transition-all',
              exit: 'opacity-1 transition-all',
              exitActive: 'opacity-0',
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              ref={videoRef}
              autoPlay
              playsInline
              muted={isMuted}
              loop={isMuted}
              className="absolute inset-0 block h-full w-full object-contain"
              src={slide.video.url}
              width={SLIDE_CONTENT_WIDTH}
              height={SLIDE_CONTENT_HEIGHT}
            />
          </CSSTransition>
          <CSSTransition
            mountOnEnter
            unmountOnExit
            in={isActive}
            timeout={VIDEO_FADE_IN_MS}
            classNames={{
              enter: 'opacity-0',
              enterActive: 'opacity-1 transition-all',
              exit: 'opacity-1 transition-all',
              exitActive: 'opacity-0',
            }}
          >
            <div className={isActive ? 'opacity-100' : ''}>
              <div
                className="h-6 w-6 cursor-pointer border-none text-white outline-none"
                onClick={toggleMute}
              >
                {isMuted ? (
                  <VolumeNo className="fill-white" />
                ) : (
                  <VolumeYes className="fill-white" />
                )}
              </div>
            </div>
          </CSSTransition>
        </>
      )}
    </Button>
  );
};

export default React.memo(TestimonialVideoPreview);
