import React from 'react';

import { parseToHtml } from '~/utils/html';

import { TestimonialCosmicMetadata } from './Testimonial.types';

type Props = {
  data: TestimonialCosmicMetadata;
};

const TestimonialDescription: React.FC<Props> = ({ data }) => {
  return (
    <div className="mr-9 flex flex-col gap-9">
      <h2 className="mobile-t3-semibold sm:web-t2-semibold">
        {parseToHtml(data.title)}
      </h2>

      <div className="body-16-regular">{data.description}</div>
    </div>
  );
};

export default React.memo(TestimonialDescription);
