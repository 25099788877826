import { cn } from '@finn/ui-utils';
import React, { useEffect, useRef, useState } from 'react';

import { TestimonialSlideMetadata } from '~/modules/cosmic/modules/Testimonial';
import { SLIDE_TRANSITION_DESKTOP_MS } from '~/modules/cosmic/modules/Testimonial/Testimonial.constants';
import TestimonialVideoPreview from '~/modules/cosmic/modules/Testimonial/TestimonialVideoPreview';

const positionClassMap = {
  prev: 'z-0',
  current: 'z-1',
  next: 'z-0',
};

type Props = {
  slide: TestimonialSlideMetadata;
  position: 'prev' | 'current' | 'next';
  isAnimated: boolean;
  isHidden: boolean;
  isRepeated: boolean;
};

const TestimonialSliderDesktopSlide: React.FC<Props> = ({
  slide,
  position,
  isAnimated,
  isHidden,
  isRepeated,
}) => {
  const className = cn(
    'flex justify-between h-full absolute inset-0',
    positionClassMap[position],
    {
      'opacity-0': isHidden,
    }
  );

  const timeoutRef = useRef(null);

  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (isRepeated) {
      return;
    }

    if (position === 'current') {
      timeoutRef.current = window.setTimeout(
        () => setIsActive(true),
        isAnimated ? SLIDE_TRANSITION_DESKTOP_MS : 0
      );
    } else {
      setIsActive(false);
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [position]);

  return (
    <div className={className}>
      <div
        className={cn(
          'flex h-full flex-col justify-between p-12 pt-14',
          {
            'opacity-0': position === 'prev' || position === 'next',
          },
          isAnimated ? 'transition-all duration-500' : ''
        )}
      >
        <h4 className="global-t5-semibold text-white">{slide.description}</h4>
        <div className="flex flex-col gap-4">
          <h6 className="global-t6-semibold text-white">{slide.name}</h6>
          <span className="body-16-regular text-white">
            {slide.vehicle_model}
          </span>
        </div>
      </div>

      <div className="relative mr-12 flex-shrink-0 basis-[240px] sm:basis-[192px] md:basis-[240px]">
        <div
          className={cn(
            'absolute inset-0 w-full',
            {
              'translate-x-[-45%] scale-90 opacity-0': position === 'prev',
              'translate-y-[-48px]': position === 'current',
              'translate-x-[calc(100%+84px)] scale-[0.8] opacity-50 ':
                position === 'next',
            },
            isAnimated ? 'transition-all duration-500' : ''
          )}
        >
          <TestimonialVideoPreview slide={slide} isActive={isActive} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(TestimonialSliderDesktopSlide);
