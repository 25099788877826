import React, { useEffect, useRef, useState } from 'react';

import { SLIDE_TRANSITION_DESKTOP_MS } from '~/modules/cosmic/modules/Testimonial/Testimonial.constants';
import TestimonialVideoPreview from '~/modules/cosmic/modules/Testimonial/TestimonialVideoPreview';

import { TestimonialSlideMetadata } from '../Testimonial.types';

type Props = {
  slide: TestimonialSlideMetadata;
  position: 'prev' | 'current' | 'next';
};

const TestimonialSliderMobileSlide: React.FC<Props> = ({ slide, position }) => {
  const timeoutRef = useRef<number | null>(null);

  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (position === 'current') {
      timeoutRef.current = window.setTimeout(
        () => setIsActive(true),
        SLIDE_TRANSITION_DESKTOP_MS
      );
    } else {
      setIsActive(false);
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [position]);

  return (
    <div className="relative w-full">
      <TestimonialVideoPreview slide={slide} isActive={isActive} />
    </div>
  );
};

export default React.memo(TestimonialSliderMobileSlide);
