import { useMediaQuery } from '@finn/design-system/helpers/media';
import { CTA } from '@finn/ui-components';
import { memo, useCallback } from 'react';

import { TestimonialCosmicMetadata } from './Testimonial.types';
import TestimonialDescription from './TestimonialDescription';
import { TestimonialPlaybackContextProvider } from './TestimonialPlaybackContext';
import TestimonialSliderDesktop from './TestimonialSliderDesktop';
import TestimonialSliderMobile from './TestimonialSliderMobile';
import TestimonialTrustpilot from './TestimonialTrustpilot';

const Testimonial = ({ data }: { data: TestimonialCosmicMetadata }) => {
  const isMobile = useMediaQuery('sm');

  const renderContent = useCallback(() => {
    if (isMobile) {
      return (
        <section className="overflow-hidden">
          <div className="container">
            <div className="flex-shrink basis-[41.6%]">
              <TestimonialDescription data={data} />
              <TestimonialTrustpilot />

              {data.cta && (
                <CTA
                  textColor="black"
                  parentBG="light"
                  data={data.cta.metadata}
                />
              )}
            </div>
          </div>

          <div className="mt-14 flex-shrink-0 basis-[58.3%] sm:min-w-[600px] md:mt-0">
            <TestimonialSliderMobile slides={data.slides} />
          </div>
        </section>
      );
    }

    return (
      <section className="overflow-hidden">
        <div className="container">
          <div className="flex-col sm:flex sm:pt-12 md:flex-row">
            <div className="flex-shrink basis-[41.6%]">
              <TestimonialDescription data={data} />
              <TestimonialTrustpilot />

              {data.cta && (
                <CTA
                  textColor="black"
                  parentBG="light"
                  data={data.cta.metadata}
                />
              )}
            </div>

            <div className="mt-14 flex-shrink-0 basis-[58.3%] sm:min-w-[600px] md:mt-0">
              <TestimonialSliderDesktop slides={data.slides} />
            </div>
          </div>
        </div>
      </section>
    );
  }, [data, isMobile]);

  return (
    <TestimonialPlaybackContextProvider>
      {renderContent()}
    </TestimonialPlaybackContextProvider>
  );
};

export default memo(Testimonial);
